import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { CheckForm } from '../features/form/formSlice';

const ProtectedRoute = ({ allowedRoles }) => {
  const location = useLocation();
  const { role } = useSelector((state) => state.form);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(CheckForm());
  }, []);

  if (role) {
    // console.log(role);
    if (allowedRoles.includes(role)) {
      return <Outlet />;
    } else {
      return <Navigate to='/' state={{ from: location }} replace />;
    }
  } else {
    return <Navigate to='/' state={{ from: location }} replace />;
  }
};

export default ProtectedRoute;
