import React, { useEffect, useState } from 'react';
import './CreateAdmin.css';
import { useDispatch, useSelector } from 'react-redux';
import { CreateAdmin } from '../features/UserThings/approvalSlice';
import { toast } from 'react-toastify';
const CreateAdmins = ({ closeModal }) => {
  const dispatch = useDispatch();
  const { AdminCreation } = useSelector((store) => store.approve);
  const { isError, isLoaded, isLoading } = AdminCreation;
  const [formData, setFormData] = useState({
    UserName: '',
    PhoneNumber: '',
    Password: '',
    Role: '',
  });
  const [passwordErrors, setPasswordErrors] = useState([]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === 'Password') {
      validatePassword(value);
    }
  };
  const validatePassword = (newPassword) => {
    const criteria = {
      length: newPassword.length >= 8,
      lowercase: /[a-z]/.test(newPassword),
      uppercase: /[A-Z]/.test(newPassword),
      number: /[0-9]/.test(newPassword),
      specialChar: /[@#$%^&*]/.test(newPassword),
    };

    const errorMessages = [];
    if (!criteria.length) errorMessages.push('At least 8 characters long.');
    if (!criteria.lowercase)
      errorMessages.push('At least one lowercase letter.');
    if (!criteria.uppercase)
      errorMessages.push('At least one uppercase letter.');
    if (!criteria.number) errorMessages.push('At least one number.');
    if (!criteria.specialChar)
      errorMessages.push(
        'At least one special character (@, #, $, %, ^, &, *).'
      );

    setPasswordErrors(errorMessages);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!/^\d{12}$/.test(formData.PhoneNumber)) {
      toast.info('Phone number must be 12 digits including the country code.');
      return;
    }
    if (passwordErrors.length > 0) {
      toast.info(
        'Please fix the following password errors:\n' +
          passwordErrors.join('\n')
      );
      return;
    }
    dispatch(CreateAdmin(formData));
  };
  useEffect(() => {
    if (isLoaded) {
      closeModal();
    }
  }, [isLoaded]);
  return (
    <div className='modal'>
      <div className='modal-content'>
        <span className='close' onClick={closeModal}>
          &times;
        </span>
        <h2 className='modal-title'>Add New Admin</h2>
        <form onSubmit={handleSubmit} className='create-admin-form'>
          <div className='form-group'>
            <label htmlFor='UserName'>User Name</label>
            <input
              type='text'
              id='UserName'
              name='UserName'
              value={formData.UserName}
              onChange={handleChange}
              required
            />
          </div>
          <div className='form-group'>
            <label htmlFor='PhoneNumber'>Phone Number</label>
            <input
              type='tel'
              id='PhoneNumber'
              name='PhoneNumber'
              pattern='[0-9]*'
              value={formData.PhoneNumber}
              onChange={handleChange}
              required
            />
          </div>
          <div className='form-group'>
            <label htmlFor='Password'>Password</label>
            <input
              type='password'
              id='Password'
              name='Password'
              value={formData.Password}
              onChange={handleChange}
              required
            />
          </div>
          <div className='form-group'>
            <label htmlFor='Role'>Role</label>
            <select
              id='Role'
              name='Role'
              value={formData.Role}
              onChange={handleChange}
              required
            >
              <option value=''>Select Role</option>
              <option value='Admin'>Admin</option>
              <option value='Verifier'>Verifier</option>
              <option value='Manager'>Manager</option>
            </select>
          </div>
          <div className='form-actions'>
            <button type='submit' className='submit-button'>
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateAdmins;
