import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './UserProfile.css';
import './CreateAdmin.css';
import CreateAdmins from './CreateAdmins';
import { Logout } from '../features/form/formSlice';
import { useNavigate } from 'react-router';

const UserProfile = () => {
  const dispatch = useDispatch();
  const { LogoutStatus } = useSelector((store) => store.form);
  const navigate = useNavigate();
  const { isLoading, isLoaded, isError } = LogoutStatus;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [randomNumber, setRandomNumber] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAdminModalOpen, setIsAdminModalOpen] = useState(false);
  const { UserName } = useSelector((store) => store.form);

  useEffect(() => {
    const max = 100;
    setRandomNumber(Math.floor(Math.random() * max));
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleCreateAdminClick = () => {
    setIsAdminModalOpen(true);
    setIsDropdownOpen(false);
  };
  const handleLogout = () => {
    dispatch(Logout());
  };

  const closeModal = () => {
    setIsAdminModalOpen(false);
    // setIsModalOpen(false);
  };

  return (
    <div className='user-profile'>
      <div onClick={toggleDropdown} className='profile-info'>
        <img
          src={`https://picsum.photos/200/300?random=${randomNumber}`}
          alt='Profile'
          className='profile-picture'
        />
        <span className='username'>{UserName}</span>
      </div>
      <div className={`dropdown-menu ${isDropdownOpen ? 'open' : ''}`}>
        {/* <div className='dropdown-item'>Your Settings</div> */}
        <div className='dropdown-item' onClick={handleCreateAdminClick}>
          Create Admin
        </div>
        <div className='dropdown-item' onClick={handleLogout}>
          Logout
        </div>
      </div>

      {isAdminModalOpen && <CreateAdmins closeModal={closeModal} />}
    </div>
  );
};

export default UserProfile;
