import React from 'react';
import UserProfile from './UserProfile';
import Alendei from '../Assets/Alendei1.png';
import { useNavigate } from 'react-router';
const Navbar = ({ isContentLoading }) => {
  const navigate = useNavigate();
  return (
    <div className='top-nav'>
      <img
        src={Alendei}
        alt='Alendei Logo'
        style={{ height: '40px', marginLeft: '-12px' }}
        onClick={() => navigate('/dashboard')}
      />
      <UserProfile />
    </div>
  );
};

export default Navbar;
