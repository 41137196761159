import React from 'react';
import { useNavigate } from 'react-router-dom';
import NotFoundImage from '../Assets/notfound.svg';
import ErrorMessage from '../Assets/404_edited.svg';

const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleBackToDashboard = () => {
    navigate('/dashboard');
  };
  const handleMouseOver = (e) => {
    e.target.style.backgroundColor = 'transparent';
    e.target.style.border = '2px solid #0d9a4b';
    e.target.style.color = 'black';
  };

  //   const handleMouseOut = (e) => {
  //     e.target.style.backgroundColor = '#0d9a4b';
  //     e.target.style.border = 'none';
  //     e.target.style.color = '#fff';
  //   };

  return (
    <div style={styles.container}>
      <p style={styles.textMessage}>PAGE NOT FOUND</p>
      <img src={NotFoundImage} alt='Not Found' style={styles.image} />
      <img src={ErrorMessage} alt='Error Message' style={styles.errorCode} />
      <div style={styles.textContainer}>
        <button
          onClick={handleBackToDashboard}
          style={styles.button}
          onMouseOver={handleMouseOver}
          onMouseOut={(e) => (e.target.style.backgroundColor = '#0d9a4b')}
        >
          Go Back
        </button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    textAlign: 'center',
  },
  errorCode: {
    height: '80px',
    marginBottom: '10px',
    position: 'absolute',
    marginTop: '-60px',
    marginLeft: '90px',
  },
  image: {
    position: 'abxolute',
    width: '27vw',
    marginBottom: '-90px',
    marginRight: '100px',
  },
  textContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '600px',
    marginRight: '90px',
  },
  textMessage: {
    position: 'absolute',
    marginBottom: '500px',
    fontSize: '3rem', // Increased font size
    fontWeight: 'bold', // Thicker font
    color: '#2f2d41', // Text color
  },
  button: {
    padding: '10px 20px',
    fontSize: '1rem',
    cursor: 'pointer',
    backgroundColor: '#0d9a4b',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    transition: 'background-color 0.3s ease', // Smooth transition for hover effect
  },
};

export default NotFoundPage;
