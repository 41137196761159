import React, { useState } from 'react';
import './index.css';
import './loginform.css';
import FormStep1 from '../Components/FormStep1';

const SignIn = () => {
  return <FormStep1 />;
};

export default SignIn;
