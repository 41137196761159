import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './Pages/SignIn';
import Dashboard from './Pages/Dashboard';
import ProtectedRoute from './Components/ProtectedRoute';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from './Components/Loading';
// import CreateAdmins from './Components/CreateAdmins';
import NotFoundPage from './Pages/NotFoundPage';

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const loadApp = async () => {
      // console.log('Loading');
      await new Promise((resolve) => setTimeout(resolve, 2200));
      setIsLoading(false);
    };

    loadApp();
  }, []);
  return (
    <Router>
      {isLoading ? (
        <Loading />
      ) : (
        <Routes>
          <Route exact path='/' element={<LoginPage />} />
          <Route
            element={<ProtectedRoute allowedRoles={['Admin', 'sudoAdmin']} />}
          >
            <Route exact path='/dashboard' element={<Dashboard />} />
          </Route>
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
      )}
      <ToastContainer
        className='toast-position'
        position='top-center'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='dark'
      />
    </Router>
  );
};

export default App;
