// src/features/form/formSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';
const url = 'https://ekyc.alendei.com';
// const url = 'http://localhost:5003';
const initialState = {
  fullName: '',
  password: '',
  continues: false,
  nameError: false,
  passwordError: false,
  Login: {
    isLoading: false,
    isError: false,
    isFulfilled: !!localStorage.getItem('isAuthenticated'),
  },
  role: localStorage.getItem('role') || '',
  UserName: localStorage.getItem('userName') || '',
  LogoutStatus: {
    isLoading: false,
    isLoaded: false,
    isError: false,
  },
};

export const submitForm = createAsyncThunk(
  'form/submitForm',
  async (params, thunkAPI) => {
    try {
      const { fullName, password } = thunkAPI.getState().form;
      const loginBody = {
        phonenumber: fullName,
        Password: password,
      };
      const response = await axios.post(
        `${url}/api/v1/admin/login`,
        loginBody,
        { withCredentials: true }
      );
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.Message || 'An error occurred';
      return thunkAPI.rejectWithValue({ message: errorMessage });
    }
  }
);
export const CheckForm = createAsyncThunk(
  'form/CheckForm',
  async (params, thunkAPI) => {
    try {
      const validity = await axios.get(`${url}/api/v1/admin/VerifyToken`, {
        withCredentials: true,
      });
      // console.log(validity);
      return validity.data;
    } catch (error) {
      const errorMessage = error.response?.data?.Message || 'An error occurred';
      return thunkAPI.rejectWithValue({ message: errorMessage });
    }
  }
);
export const Logout = createAsyncThunk(
  'form/Logout',
  async (params, thunkAPI) => {
    try {
      const LogoutCookie = await axios.get(`${url}/api/v1/admin/logout`, {
        withCredentials: true,
      });
      return LogoutCookie.data;
    } catch (error) {
      const errorMessage = error.response?.data?.Message || 'An error occurred';
      return thunkAPI.rejectWithValue({ Message: errorMessage });
    }
  }
);

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setFullName: (store, action) => {
      store.fullName = action.payload;
    },
    setPassword: (store, action) => {
      store.password = action.payload;
    },
    setNameError: (store, action) => {
      store.nameError = action.payload;
    },
    setPasswordError: (store, action) => {
      store.passwordError = action.payload;
    },
    setContinues: (store, action) => {
      store.continues = action.payload;
    },
    setRole: (store, { payload }) => {
      store.role = payload;
    },
    clearAuth: (store) => {
      store.role = '';
      store.Login.isFulfilled = false;
      localStorage.removeItem('role');
      localStorage.removeItem('isAuthenticated');
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submitForm.pending, (store) => {
      store.Login.isLoading = true;
      store.Login.isFulfilled = false;
      store.Login.isError = false;
    });
    builder.addCase(submitForm.fulfilled, (store, { payload }) => {
      store.Login.isLoading = false;
      store.Login.isFulfilled = true;
      store.Login.isError = false;
      store.role = payload.role;
      store.UserName = payload.UserName;
      localStorage.setItem('userName', payload.UserName);
      localStorage.setItem('role', payload.role);
      localStorage.setItem('isAuthenticated', 'true');
      store.Login.isError = false;
      toast.success(`Welcome Back ${store.UserName}`);
    });
    builder.addCase(submitForm.rejected, (store, { payload }) => {
      store.Login.isLoading = false;
      store.Login.isError = true;
      store.Login.isFulfilled = false;
      const errorMessage = payload?.data?.Message || 'An error occurred';
      toast.error(`Login failed: ${errorMessage}`);
    });
    //VERIFY TOKEN
    builder.addCase(CheckForm.pending, (store) => {
      // console.log('Loading');
    });
    builder.addCase(CheckForm.fulfilled, (store, { payload }) => {
      // console.log('verified');
      // console.log(payload.Role);
      store.role = payload.Role;
      localStorage.setItem('role', payload.Role);
      // localStorage.removeItem('role', '');
    });
    //CHECK HERE FOR DOUBLE LOAD
    builder.addCase(CheckForm.rejected, (store, { payload }) => {
      localStorage.removeItem('role');
      localStorage.removeItem('isAuthenticated');
      localStorage.removeItem('userName');
      store.role = '';
      store.UserName = '';
      store.Login.isFulfilled = false;
    });
    //LOGOUT
    builder.addCase(Logout.pending, (store) => {
      store.LogoutStatus.isLoading = true;
      store.LogoutStatus.isLoaded = false;
      store.LogoutStatus.isError = false;
    });
    builder.addCase(Logout.fulfilled, (store, { payload }) => {
      localStorage.removeItem('role');
      localStorage.removeItem('isAuthenticated');
      localStorage.removeItem('userName');
      store.role = '';
      store.UserName = '';
      store.Login.isFulfilled = false;
      store.LogoutStatus.isLoading = false;
      store.LogoutStatus.isLoaded = true;
      store.LogoutStatus.isError = false;
      toast.success('Logout Successful');
    });
    builder.addCase(Logout.rejected, (store, { payload }) => {
      store.LogoutStatus.isLoading = false;
      store.LogoutStatus.isLoaded = false;
      store.LogoutStatus.isError = true;
      toast.error(payload.Message);
      //   console.log(`${payload.data.Message}`);
    });
  },
});

export const {
  setFullName,
  setNameError,
  setPasswordError,
  setPassword,
  setContinues,
} = formSlice.actions;

export default formSlice.reducer;
